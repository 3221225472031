<template>
  <b-container class="mb-4">
    <b-jumbotron v-for="item in banners" :key="item.file" style="text-align: center" class="pt-5">
      <a rel="noopener noreferrer" :href="item.url" :target="item.newWindow ? '_blank' : '_self'">
        <img :src="item.file" />
        <div v-html="item.title" class="page-content" />
      </a>
    </b-jumbotron>
  </b-container>
</template>

<script>
import $ from 'jquery'
import { mapState } from 'vuex'
import { isCSR } from '@/utils'
import updateSeoInfo from '@/utils/meta'
import Lifecycle from '@/mixins/lifecycle'
export default {
  name: 'main-layout',
  data() {
    return {
      isMounted: false,
      data: ''
    }
  },
  mixins: [Lifecycle],
  components: {},
  computed: {
    ...mapState('common', ['menu']),
    ...mapState('page', ['banners']),
    alreadyInited: function() {
      return !!this.menu
    }
  },
  serverPrefetch() {
    return this.fetchData()
  },
  seoInfo() {
    try {
      return {
        title: this.page.metaTitle,
        description: this.page.metaDescription || this.page.metaTitle,
        keywords: this.page.metaKeywords || this.page.metaTitle,
        metas: this.page.metaExtras
      }
    } catch (err) {}
  },
  mounted() {
    this.$bar.start()
    this.fetchData().finally(() => this.$bar.finish())
  },
  methods: {
    fetchData() {
      let path = this.$route.path.replace(`/${this.$route.params.lang}/`, '')
      path = path.replace(`preview/`, '')
      const cookies = this.$ssrContext && this.$ssrContext.cookies
      const { preview, id, vid } = this.$route.query
      return new Promise((resolve, reject) => {
        Promise.all([
          this.fetchInitData(),
          this.$store.dispatch('page/FETCH_BANNERS', {
            cookies,
            id,
            preview,
            vid
          })
        ])
          .finally(() => {
            resolve(updateSeoInfo.call(this))
          })
          .catch(reject)
      })
    }
  }
}
</script>
